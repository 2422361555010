.testimonial-section {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 52px;
    max-width: 100%;
    padding: 5vw 22vw;
    position: relative;
    /* width: 100%; */
}