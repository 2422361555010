:root {
  --body-font-family: "Work Sans", Helvetica;
  --body-font-size: 13px;
  --body-font-style: normal;
  --body-font-weight: 400;
  --body-letter-spacing: -0.26px;
  --body-line-height: normal;
  --critical: rgba(255, 51, 51, 1);
  --fuschia-100: rgba(239, 93, 168, 1);
  --fuschia-60: rgba(252, 221, 236, 1);
  --fuschia-80: rgba(241, 120, 182, 1);
  --h1-style-font-family: "Montserrat", Helvetica;
  --h1-style-font-size: 2.25vw;
  --h1-style-font-style: normal;
  --h1-style-font-weight: 300;
  --h1-style-letter-spacing: 0px;
  --h1-style-line-height: normal;
  --h2-small-font-family: "Montserrat", Helvetica;
  --h2-small-font-size: 1.75vw;
  --h2-small-font-style: normal;
  --h2-small-font-weight: 400;
  --h2-small-letter-spacing: 0.96px;
  --h2-small-line-height: 128.99999618530273%;
  --h2-style-font-family: "Montserrat", Helvetica;
  --h2-style-font-size: 2vw;
  --h2-style-font-style: normal;
  --h2-style-font-weight: 400;
  --h2-style-letter-spacing: 1.08px;
  --h2-style-line-height: 128.99999618530273%;
  --h3-style-font-family: "Montserrat", Helvetica;
  --h3-style-font-size: 1.5vw;
  --h3-style-font-style: normal;
  --h3-style-font-weight: 400;
  --h3-style-letter-spacing: 0.84px;
  --h3-style-line-height: 128.99999618530273%;
  --header-1-font-family: "Work Sans", Helvetica;
  --header-1-font-size: 34px;
  --header-1-font-style: normal;
  --header-1-font-weight: 700;
  --header-1-letter-spacing: -0.68px;
  --header-1-line-height: normal;
  --header-2-font-family: "Work Sans", Helvetica;
  --header-2-font-size: 20px;
  --header-2-font-style: normal;
  --header-2-font-weight: 700;
  --header-2-letter-spacing: -0.4px;
  --header-2-line-height: normal;
  --high: rgba(255, 153, 0, 1);
  --iris-100: rgba(93, 95, 239, 1);
  --iris-60: rgba(165, 166, 246, 1);
  --iris-80: rgba(120, 121, 241, 1);
  --low: rgba(71, 254, 44, 1);
  --medium: rgba(255, 214, 51, 1);
  --primary: rgba(0, 255, 178, 1);
  --primary-dk: rgba(1, 14, 10, 1);
  --primary-light: rgba(245, 255, 252, 1);
  --secondary: rgba(255, 187, 51, 1);
  --variable-collection-primary-dark: rgba(47, 209, 160, 1);
  --variable-collection-primary-dk: rgba(13, 72, 54, 1);
  --variable-collection-primary-light: rgba(196, 248, 232, 1);
  --variable-collection-primary-medium: var(--variable-collection-primary-dk);
}
