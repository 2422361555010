.contactus-main-section {
    display: flex;
    width: 100vw;
}

.left-section,
.right-section {
    flex: 1;
    padding: 40px;
}

.left-section {
    background-color: var(--primary-dk);
    width: 50vw;
    box-sizing: border-box;
    padding: 5vw 10vw;
}

.right-section {
    background-color: var(--primary-dk);
    color: #101010;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    box-sizing: border-box;
    padding: 5vw;
}

h1 {
    color: white;
    font-size: 2.5vw;
    font-family: 'Montserrat', sans-serif;
}

p {
    margin-top: 20px;
    font-size: 1.25vw;
    line-height: 1.6;
    font-family: 'Montserrat', sans-serif;
}

.cta-button {
    margin-top: 20px;
    padding: 1vw 2vw;
    background-color: var(--primary);
    color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25vw;
    width: 25vw;
}

.code-block {
    margin-top: 40px;
    background-color: #202020;
    color: #4caf50;
    padding: 20px;
    border-radius: 10px;
    font-family: 'Courier New', Courier, monospace;
}

.alert-box {
    margin-top: 20px;
    padding: 20px;
    background-color: #2a2a2a;
    border-left: 5px solid #4caf50;
}

.form {
    width: 100%;
    background-color: var(--primary-light);
    padding: 2vw;
    border-radius: 0.5vw;
    font-family: 'Montserrat', sans-serif;
}

.form-group {
    margin-bottom: 20px;
    display: flex;
}

label {
    display: block;
    margin-bottom: 8px;
    font-size: 0.9rem;
    font-family: 'Montserrat', sans-serif;
    width: 30vw;
}

input,
select,
textarea,
button {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

button.submit-button {
    background-color: var(--primary);
    ;
    color: #000;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
}

button.submit-button:hover {
    background-color: #45a049;
}

.toggle-group {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    margin-top: 10px;
}

.toggle-input {
    display: none;
}

.toggle-label {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #ccc;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
}

.toggle-label:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: white;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    transition: transform 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.toggle-input:checked+.toggle-label {
    background-color: var(--variable-collection-primary-dk);
}

.toggle-input:checked+.toggle-label:before {
    transform: translateX(30px);
}

.toggle-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: white;
    pointer-events: none;
}