.navbar2 {
    align-items: center;
    background-color: #000;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: rgba(212, 212, 212, 0.5);
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 2vw 5vw;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 13vh;
    max-height: 13vh;
}

.brand-logo-lt {
    background-image: url(../pages/assests/brand-logo-lt.svg);
    background-size: 100% 100%;
    height: inherit;
    position: relative;
    width: 10vw;
    cursor: pointer;
}

.navlinks {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 2vw;
    position: relative;
}

.links {
    color: #b4b4b4;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.10vw;
    font-weight: 400;
    height: 25px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    cursor: pointer;
}

.links.active {
    font-size: 1.2vw;
    font-weight: 500;
    color: var(--primary);
    border-bottom: 0.5px solid var(--primary);
}

.nav-btn2 {
    align-items: center;
    background-color: #0f1a1b;
    border: 1px solid;
    border-color: #D4D4D4;
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 1vw 1.5vw;
    position: relative;
    cursor: pointer;
}

.text-wrapper-42 {
    color: #D4D4D4;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25vw;
    font-weight: 400;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}